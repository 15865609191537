import React from "react"
import Layout from "../components/Layout"
import big from "../assets/images/bgmain.jpg"
import styled from "styled-components"
import "bootstrap/dist/css/bootstrap.min.css"
import { Container, Row, Col } from "react-bootstrap"

//para espacio de spacer hacer pequeno el background
const spacer = 20

//para agrandamiento de segundo porcentaje de background
const percentage = 200

const MensajeBox = styled.div`
  font-size: 58px;
  font-weight: 700;
  font-family: "Titillium Web";
  letter-spacing: -2px;
  text-align: center;
  padding-top: 250px;
  padding-bottom: 250px;
`

const Error = () => {
  return (
    <Layout sbiggo={big} spacer={spacer} percentage={percentage}>
      <Container>
        <Row>
          <Col>
            <MensajeBox>
              El recurso buscado no se encuentra en este sitio web
            </MensajeBox>
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default Error
